import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import QRCode from 'qrcode.react';

const BoardUser = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    UserService.getUserBoard().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>Virtual Hub Container Information</h3>
      </header>
      <p>
        <strong>Default Username:</strong> Administrator<br />
        <strong>Default Password:</strong> 123456
      </p>
      <p>
        <a className="btn-color btn btn-block" href={`http://${content.host}:${content.httpPort}`} target="_blank">Hub Web Console</a>
      </p>
      <p>
      <strong>Hub App Console:</strong> (Scan QR code using EVVR app)
      <br /><QRCode value={`{"url": "${content.host}", "port": "${content.mqttPort}", "virtualHubId": "${content.containerId}"}`} renderAs="canvas" id={"app"} includeMargin />
      </p>
    </div>
  );
};

export default BoardUser;
